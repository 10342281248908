@use '../../style/colors' as color;

.pbg-file-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.pbg-file-card-list-empty {
  font-size: 1.5rem;
  text-align: center;
}
.pbg-file-card-item {
  margin: 0 0 2rem 0;
  padding: 0 1rem;
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: 1100px) {
    width: 33.3333333%;
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  }
  >div {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .pbg-file-card-item-content {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
  }
  .pbg-file-card-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem 0 0;
  }
  .pbg-file-card-right {
    display: flex;
    align-items: center;
    border-left: 1px solid color.$border-light;
    padding: 0 0 0 1.5rem;
  }
  .pbg-file-card-type {
    margin: 0 0 0.6rem 0;
    font-weight: 700;
  }
  .pbg-file-card-title {
    font-weight: 900;
    margin: 0 0 1.4rem 0;
    font-size: 1.3rem;
    a {
      color: color.$text-base;
      &:hover {
        color: color.$background-accent;
      }
    }
  }
  .pbg-file-card-language {
    font-size: 1.1rem;
    margin: 0 0 1rem 0;
  }
  .pbg-file-card-details {
    color: color.$text-secondary;
  }
  .pbg-file-card-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.10);
    background: color.$background-accent;
    transition: all .3s;
    &:hover {
      @media screen and (min-width: 1100px) {
        background: color.$interaction-accent;
      }
    }
    svg {
      display: block;
      height: 1.75rem;
      width: 1.75rem;
      fill: white;
    }
  }
}