@use '../../style/colors' as color;

.pbg-footer {
  background: white;
  margin: 4rem 0 0 0;
  .pbg-footer-container {
    width: 100%;
    padding: 0 1.2rem;
    max-width: 1800px;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      padding: 0 2.5rem;
    }
    @media screen and (min-width: 1100px) {
      padding: 0 4rem;
    }
    .pbg-footer-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex: 1;
      text-align: center;
      padding: 4rem 0;
      color: color.$text-secondary;
      .pbg-footer-small {
        font-size: 0.9rem;
        line-height: 1.2rem;
        margin: 0.8rem 0 0 0;
      }
    }
  }
}