@use '../../style/colors' as color;

.pbg-language-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.pbg-language-card-list-empty {
  font-size: 1.5rem;
  text-align: center;
}
.pbg-language-card-item {
  margin: 0 0 2rem 0;
  padding: 0 1rem;
  width: 50%;
  flex-basis: 50%;
  max-width: 50%;
  @media screen and (min-width: 768px) {
    width: 33.3333333%;
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  }
  @media screen and (min-width: 1100px) {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .pbg-language-card-item-content {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    @media screen and (min-width: 400px) {
      padding: 1.5rem;
      font-size: 1.3rem;
      text-align: left;
    }
    a {
      display: flex;
      align-items: center;
      color: color.$text-base;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      &:hover {
        .pbg-language-card-icon {
          @media screen and (min-width: 1100px) {
            background: color.$interaction-accent;
          }
        }
      }
    }
  }
  .pbg-language-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    width: 2.8rem;
    min-width: 2.8rem;
    border-radius: 50%;
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.10);
    background: color.$background-accent;
    transition: all .3s;
    color: white;
    font-size: 1rem;
    font-weight: 900;
    margin: 0 0 1rem 0;
    @media screen and (min-width: 768px) {
      margin: 0 1rem 0 0;
    }
    svg {
      display: block;
      height: 1.75rem;
      width: 1.75rem;
      fill: white;
    }
  }
}