@use '../../style/colors' as color;

.pbg-hero {
  background: color.$background-primary;
  height: 100vh;
  position: relative;
  .pbg-hero-background {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      fill: #f2f2f2;
    }
    .pbg-hero-background-element-1 {
      animation: herorotate 20s linear infinite;
      transform-origin: 13px 25px;
    }
    .pbg-hero-background-element-2 {
      animation: herorotate 25s linear infinite;
      transform-origin: 84px 93px;
    }
    @keyframes herorotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .pbg-hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    z-index: 1;
    position: relative;
    flex-direction: column;
    padding: 60px 0 0 0;
    @media screen and (min-width: 1100px) {
      padding: 70px 0 0 0;
    }
    .pbg-hero-heading {
      font-size: 2.5rem;
      margin: 0;
      color: color.$text-base;
      font-weight: 900;
      letter-spacing: 0.5rem;
      @media screen and (min-width: 350px) {
        font-size: 3rem;
      }
      @media screen and (min-width: 450px) {
        font-size: 4rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 6rem;
      }
      @media screen and (min-width: 1100px) {
        font-size: 8rem;
      }
      &::after {
        content: '';
        display: block;
        background: color.$background-accent;
        height: 0.7rem;
        width: 60%;
        margin: 1.2rem auto 0 auto;
        @media screen and (min-width: 768px) {
          height: 0.8rem;
        }
        @media screen and (min-width: 1100px) {
          height: 1rem;
        }
      }
    }
    .pbg-hero-buttons {
      margin: 3rem 0 0 0;
      display: flex;
      justify-content: center;
      button {
        display: inline-block;
        background: transparent;
        border: 1px solid #787878;
        color: #787878;
        padding: 1.3rem 2rem;
        border-radius: 4px;
        transition: all .3s;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1.1rem;
        &:hover {
          @media screen and (min-width: 1100px) {
            border: 1px solid color.$background-accent;
            color: white;
          }
          &::before {
            @media screen and (min-width: 1100px) {
              left: 0;
              right: 0;
              opacity: 1;
            }
          }
        }
        &::before {
          content: '';
          transition: 0.5s all ease;
          position: absolute;
          top: 0;
          left: 50%;
          right: 50%;
          bottom: 0;
          opacity: 0;
          background-color: color.$background-accent;
          z-index: -2;
        }
      }
    }
  }
}