@use '../../style/colors' as color;

.pbg-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  background: white;
  height: 60px;
  width: 100%;
  transition: all .3s ease-out;
  flex-direction: row;
  @media screen and (min-width: 1100px) {
    height: 70px;
  }
  .pbg-header-wrapper {
    width: 100%;
    z-index: 1;
    .pbg-header-container {
      background: white;
      height: 100%;
      width: 100%;
      padding: 0 1.2rem;
      max-width: 1800px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        padding: 0 2.5rem;
      }
      @media screen and (min-width: 1100px) {
        padding: 0 4rem;
      }
      .pbg-header-content {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        flex: 1;
      }
    }
  }
  .pbg-header-menu-button {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    button {
      position: relative;
      height: 2.25rem;
      width: 2.25rem;
      transition: all .3s;
      transform: scale(1);
      &:hover {
        @media screen and (min-width: 1100px) {
          transform: scale(1.1);
        }
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 2.25rem;
        width: 2.25rem;
        fill: color.$text-base;
        transition: all .3s;
      }
      .pbg-header-menu-button-closed {
        opacity: 1;
      }
      .pbg-header-menu-button-open {
        opacity: 0;
      }
    }
  }
  .pbg-header-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    a {
      display: inline-block;
      transform: scale(1);
      transition: all .5s;
      &:hover {
        @media screen and (min-width: 1100px) {
          transform: scale(1.1);
        }
      }
      svg {
        height: 3rem;
        width: 3.8rem;
        fill: color.$text-accent;
        @media screen and (min-width: 1100px) {
          height: 3.5rem;
          width: 4.45rem;
        }
      }
    }
  }
}
.pbg-header-menu {
  position: fixed;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  height: calc(100% - 60px);
  min-height: calc(100% - 60px);
  transform: translateY(calc(-100% - 60px));
  transition: all .3s ease-out;
  @media screen and (min-width: 1100px) {
    top: 70px;
    height: calc(100% - 70px);
    min-height: calc(100% - 70px);
    transform: translateY(calc(-100% - 70px));
  }
  nav {
    overflow-y: auto;
    padding: 5rem 0 10rem 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 1800px;
      margin: 0 auto;
      width: 100%;
      list-style-type: none;
      text-align: left;
      opacity: 0;
      transition: all .6s .4s ease-out;
      padding: 0 1.2rem;
      @media screen and (min-width: 768px) {
        padding: 0 2.5rem;
      }
      @media screen and (min-width: 1100px) {
        padding: 0 4rem;
      }
      li {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 50%;
        flex-basis: 50%;
        padding: 2rem 1rem;
        @media screen and (min-width: 768px) {
          padding: 2.5rem 1rem;
        }
        @media screen and (min-width: 1100px) {
          width: 33.3333333%;
          flex-basis: 33.3333333%;
          padding: 3rem 1rem;
        }
        @media screen and (min-width: 1400px) {
          width: 25%;
          flex-basis: 25%;
          padding: 4rem 1rem;
        }
      }
      >li:nth-child(1), >li:nth-child(2), >li:nth-child(3), >li:nth-child(4), >li:nth-child(5), >li:nth-child(6), >li:nth-child(7), >li:nth-child(8) {
        @media only screen and (max-width: 1099px) {
          border-bottom: 1px solid color.$border-light;
        }
      }
      >li:nth-child(1), >li:nth-child(3), >li:nth-child(5), >li:nth-child(7), >li:nth-child(9) {
        @media only screen and (max-width: 1099px) {
          border-right: 1px solid color.$border-light;
        }
      }
      >li:nth-child(1), >li:nth-child(2), >li:nth-child(3), >li:nth-child(4), >li:nth-child(5), >li:nth-child(6) {
        @media only screen and (min-width: 1100px) and (max-width: 1399px) {
          border-bottom: 1px solid color.$border-light;
        }
      }
      >li:nth-child(1), >li:nth-child(2), >li:nth-child(4), >li:nth-child(5), >li:nth-child(7), >li:nth-child(8) {
        @media only screen and (min-width: 1100px) and (max-width: 1399px) {
          border-right: 1px solid color.$border-light;
        }
      }
      >li:nth-child(1), >li:nth-child(2), >li:nth-child(3), >li:nth-child(4), >li:nth-child(5), >li:nth-child(6), >li:nth-child(7), >li:nth-child(8) {
        @media only screen and (min-width: 1400px) {
          border-bottom: 1px solid color.$border-light;
        }
      }
      >li:nth-child(1), >li:nth-child(2), >li:nth-child(3), >li:nth-child(5), >li:nth-child(6), >li:nth-child(7), >li:nth-child(9) {
        @media only screen and (min-width: 1400px) {
          border-right: 1px solid color.$border-light;
        }
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        font-size: 1.3rem;
        color: color.$text-base;
        line-height: normal;
        text-align: center;
        transition: all .3s ease-out;
        transform: scale(1);
        @media screen and (min-width: 768px) {
          font-size: 1.4rem;
        }
        @media screen and (min-width: 1100px) {
          font-size: 1.5rem;
        }
        &.active {
          font-weight: 900;
          color: color.$text-accent;
        }
        &:hover {
          @media screen and (min-width: 1100px) {
            color: color.$text-accent;
            transform: scale(1.05);
          }
        }
        svg {
          display: block;
          height: 1.9rem;
          width: 1.9rem;
          fill: currentColor;
          transition: transform .3s;
          margin: 0 0 1rem 0;
          @media screen and (min-width: 1100px) {
            height: 2.5rem;
            width: 2.5rem;
            margin: 0 0 1.4rem 0;
          }
        }
      }
    }
  }
  .pbg-header-menu-homepage {
    background: white;
    border-top: 1px solid color.$border-light;
    padding: 1.5rem 0 2.5rem 0;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all .3s .3s;
    a {
      color: color.$text-base;
      font-weight: 400;
      font-size: 1.1rem;
      &:hover {
        @media screen and (min-width: 1100px) {
          color: color.$text-accent;
        }
      }
    }
  }
}
.pbg-header.open {
  .pbg-header-menu-button button {
    transform: rotate(180deg);
    &:hover {
      @media screen and (min-width: 1100px) {
        transform: rotate(180deg) scale(1.1);
      }
    }
    .pbg-header-menu-button-closed {
      opacity: 0;
    }
    .pbg-header-menu-button-open {
      opacity: 1;
    }
  }
  .pbg-header-menu {
    transform: translateY(0%);
    ul {
      opacity: 1;
    }
    .pbg-header-menu-homepage {
      opacity: 1;
    }
  }
}
.pbg-header.closed.hidden.offset-true {
  top: -60px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0);
  @media screen and (min-width: 1100px) {
    top: -70px
  }
}
.pbg-header.closed.offset-true {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
}