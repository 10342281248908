// --------------- Colors ---------------
/* ----- Background ----- */
$background-base: #fff;
$background-primary: #f8f8f8;
$background-accent: #48c5d6;
/* ----- Text ----- */
$text-base: #414141;
$text-secondary: #777;
$text-accent: #48c5d6;
/* ----- Border ----- */
$border-light: #f1f1f1;
$border-dark: #ddd;
/* ----- Interaction ----- */
$interaction-accent: #49a9b6;