@use './colors' as color;

.pbg-search {
  position: relative;
  width: 100%;
  max-width: 500px;
  &::after {
    content: '';
    display: block;
    background: color.$background-accent;
    height: 0.4rem;
    width: 10rem;
    margin: 1.2rem auto 0 auto;
    @media screen and (min-width: 768px) {
      margin: 1.2rem 0 0 0;
    }
    @media screen and (min-width: 1100px) {
      height: 0.4rem;
    }
  }
  .pbg-search-field {
    background: white;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
    border: 0;
    border-radius: 10px;
    padding: 0 1rem;
    height: 3rem;
    width: 100%;
    color: color.$text-base;
    font-weight: 900;
    font-size: 1.7rem;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
      text-align: left;
    }
    @media screen and (min-width: 1100px) {
      height: 3.5rem;
    }
  }
  .pbg-search-field::-webkit-input-placeholder {
    opacity: 1;
    color: color.$text-base;
    font-weight: 900;
    font-size: 1.7rem;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
  }
  .pbg-search-field:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  .pbg-search-field:-moz-placeholder {
    opacity: 1;
    color: color.$text-base;
    font-weight: 900;
    font-size: 1.7rem;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
  }
  .pbg-search-field:focus:-moz-placeholder {
    opacity: 0;
  }
  .pbg-search-field::-moz-placeholder {
    opacity: 1;
    color: color.$text-base;
    font-weight: 900;
    font-size: 1.7rem;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
  }
  .pbg-search-field:focus::-moz-placeholder {
    opacity: 0;
  }
  .pbg-search-field:-ms-input-placeholder {
    opacity: 1;
    color: color.$text-base;
    font-weight: 900;
    font-size: 1.7rem;
    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
    }
  }
  .pbg-search-field:focus:-ms-input-placeholder {
    opacity: 0;
  }
}