@use '../../style/colors' as color;

.pbg-page-header {
  overflow: hidden;
  background: color.$background-primary;
  position: relative;
  margin: 0 0 5rem 0;
  padding: 8rem 0 4rem 0;
  @media screen and (min-width: 768px) {
    padding: 12rem 0 6rem 0;
  }
  .pbg-hero-background {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      fill: #f2f2f2;
    }
    .pbg-hero-background-element-1 {
      animation: herorotate 20s linear infinite;
      transform-origin: 13px 25px;
    }
    .pbg-hero-background-element-2 {
      animation: herorotate 25s linear infinite;
      transform-origin: 84px 93px;
    }
    @keyframes herorotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .pbg-page-header-container {
    z-index: 1;
    position: relative;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 1.2rem;
    @media screen and (min-width: 768px) {
      padding: 0 2.5rem;
    }
    @media screen and (min-width: 1100px) {
      padding: 0 4rem;
    }
  }
  .pbg-page-header-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      align-items: flex-start;
      flex-direction: row;
    }
    svg {
      fill: color.$text-base;
      height: 2.5rem;
      width: 2.5rem;
      margin: 0 0 1rem 0;
      @media screen and (min-width: 768px) {
        height: 3rem;
        width: 3rem;
        margin: 0 1rem 0 0;
      }
      @media screen and (min-width: 1100px) {
        height: 3.5rem;
        width: 3.5rem;
      }
    }
    h1 {
      margin: 0;
      font-weight: 900;
      font-size: 2.2rem;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 2.9rem;
        text-align: left;
      }
      @media screen and (min-width: 1100px) {
        font-size: 3.7rem;
      }
      &::after {
        content: '';
        display: block;
        background: color.$background-accent;
        height: 0.4rem;
        width: 10rem;
        margin: 1.2rem auto 0 auto;
        @media screen and (min-width: 768px) {
          margin: 1.2rem 0 0 0;
        }
        @media screen and (min-width: 1100px) {
          height: 0.5rem;
        }
      }
    }
  }
  .pbg-page-header-subtitle {
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    color: color.$text-secondary;
    margin: 1rem 0 0 0;
    text-align: center;
    @media screen and (min-width: 768px) {
      margin: 1rem 0 0 4rem;
      text-align: left;
    }
    @media screen and (min-width: 1100px) {
      margin: 1rem 0 0 4.5rem;
    }
  }
}